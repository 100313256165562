import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import localforage from 'localforage';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './global.css';
import * as serviceWorker from './serviceWorker';

const cache = new InMemoryCache();

(async () => {
  await persistCache({
    cache,
    storage: localforage as any,
  });

  const client = new ApolloClient({
    uri: '/.netlify/functions/api',
    cache,
  });

  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
})();
