import React from 'react';
import { animated, config, useSpring } from 'react-spring';
import { Flex, Heading, Text } from 'rebass';

interface Props {
  location?: string;
  confirmed?: number;
  increase?: number;
  lastUpdate?: string;
  max?: number;
}

const states = [
  {
    fullName: 'South Australia',
    shortName: 'SA',
  },
  {
    fullName: 'Victoria',
    shortName: 'VIC',
  },
  {
    fullName: 'Tasmania',
    shortName: 'TAS',
  },
  {
    fullName: 'Western Australia',
    shortName: 'WA',
  },
  {
    fullName: 'Queensland',
    shortName: 'QLD',
  },
  {
    fullName: 'Australian Capital Territory',
    shortName: 'ACT',
  },
  {
    fullName: 'Northern Territory',
    shortName: 'NT',
  },
  {
    fullName: 'New South Wales',
    shortName: 'NSW',
  },
];

const Wrapper = animated(Flex) as any;

const getStateName = (location?: string) =>
  states.find(state => state.fullName === location)?.shortName;

const Case: React.FC<Props> = ({
  confirmed,
  increase,
  location,
  lastUpdate,
  max,
}) => {
  const hue = ((confirmed || 0) / (max || 0)) * 100 + 240;
  const { confirmedNumber, increaseNumber, opacity } = useSpring({
    confirmedNumber: confirmed,
    increaseNumber: increase,
    opacity: 1,
    from: { confirmedNumber: 0, increaseNumber: 0, opacity: 0 },
    config: config.gentle,
  });

  return (
    <Wrapper
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ opacity }}
    >
      <Heading color="#fff" textAlign="center" mb="2">
        {getStateName(location)}
      </Heading>
      <Flex
        padding="2"
        width="200px"
        height="200px"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: '100%',
          position: 'relative',
          background: `linear-gradient(to right, hsl(${hue -
            60}, 85%, 50%), hsl(${hue}, 85%, 50%))`,
        }}
      >
        <Flex
          bg="#000"
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent="center"
          sx={{ textAlign: 'center', borderRadius: 'inherit' }}
        >
          <Text color="#fff" fontSize="6" fontWeight="bold" lineHeight="1.25">
            <animated.span>
              {confirmedNumber.to(n => n?.toFixed(0))}
            </animated.span>
          </Text>
          <Text
            color="#FF9900"
            fontSize="3"
            fontWeight="bold"
            textAlign="center"
            title={`Since ${lastUpdate}`}
            lineHeight="1"
          >
            +
            <animated.span>
              {increaseNumber.to(n => n?.toFixed(0))}
            </animated.span>
            <Text
              fontSize="0.5rem"
              sx={{ textTransform: 'uppercase' }}
              fontWeight="normal"
              lineHeight="1.25"
              mt={1}
            >
              Since <br /> yesterday
            </Text>
          </Text>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default Case;
