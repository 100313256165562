import { useQuery } from '@apollo/react-hooks';
import { Tiles } from '@rebass/layout';
import theme from '@rebass/preset';
import { gql } from 'apollo-boost';
import { ThemeProvider } from 'emotion-theming';
import React, { useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { Box, Flex, Heading, Link, Text } from 'rebass';
import Case from './Case';
import { AllCaseReports } from './types/graphql';

const GET_ALL_CASE_REPORTS = gql`
  query AllCaseReports {
    allCaseReports {
      data {
        _ts
        date
        totalConfirmed
        cases {
          data {
            _id
            _ts
            location
            confirmed
          }
        }
      }
    }
  }
`;

const App: React.FC = () => {
  const { data, loading } = useQuery<AllCaseReports>(GET_ALL_CASE_REPORTS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 600000,
  });

  const sortedReports = data?.allCaseReports.data.sort(
    (a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime()
  );

  const [currentReports, previousReports] = sortedReports || [];

  const cases = useMemo(
    () =>
      currentReports?.cases.data.sort((a, b) => {
        return a?.location.localeCompare(b?.location || '') || 0;
      }),
    [currentReports]
  );

  const max = Math.max(
    ...(currentReports?.cases.data.map(el => el?.confirmed || 0) || [0])
  );

  return (
    <ThemeProvider theme={theme}>
      <Box variant="styles.root">
        {currentReports?._ts && (
          <>
            <Heading
              color="#fff"
              textAlign="center"
              my="4"
              fontSize="40vw"
              opacity={0.07}
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
              }}
            >
              {currentReports?.totalConfirmed.toLocaleString('en-au')}
            </Heading>
            <Flex
              justifyContent="center"
              flexDirection="column"
              minHeight="calc(100vh - 60px)"
            >
              <Tiles
                columns={[0, 2, 3, 4]}
                justifyItems="center"
                gap={[4, null, 5]}
                padding="4"
                maxWidth="1400px"
                margin="0 auto"
              >
                {cases?.map(report => {
                  const previousReport = previousReports?.cases.data.find(
                    r => r?.location === report?.location
                  );

                  const increase =
                    (report?.confirmed || 0) - (previousReport?.confirmed || 0);

                  const lastUpdate = new Date(
                    previousReport?._ts / 1000
                  ).toLocaleString('en-au');

                  return (
                    <Case
                      location={report?.location}
                      confirmed={report?.confirmed}
                      increase={increase}
                      lastUpdate={lastUpdate}
                      max={max}
                      key={report?._id}
                    />
                  );
                })}
              </Tiles>
            </Flex>
            <Flex
              height="60px"
              alignItems="center"
              justifyContent="center"
              px="4"
              sx={{
                borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                color: '#fff',
                fontSize: 1,
                textAlign: 'center',
              }}
            >
              <Text mr="3">
                <strong>Source</strong>{' '}
                <Link
                  href="https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/coronavirus-covid-19-current-situation-and-case-numbers"
                  color="#fff"
                >
                  www.health.gov.au
                </Link>
              </Text>
              <Text ml="3">
                <strong>Last updated</strong>{' '}
                {new Date(currentReports?._ts / 1000).toLocaleTimeString(
                  'en-au'
                )}
              </Text>
            </Flex>
          </>
        )}
        {loading && !currentReports?._ts && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Loader type="Circles" color="#FF9900" height={40} width={40} />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default App;
